/**
 * Date Slider
 * @description : Swiper for the Date Slider module.
 */

window.setUpDateSlider = function (dateSliderEl) {

    var swiperEl = dateSliderEl.querySelector('.js-date-slider__swiper');

    if (swiperEl) {
        var scrollbarEl = dateSliderEl.querySelector('.js-date-slider__scrollbar');
        new Swiper(swiperEl, {
            spaceBetween: 0,
            slidesPerView: 'auto',
            direction: 'horizontal',
            loop: false,
            scrollbar: {
                el: scrollbarEl,
                draggable: true,
                dragClass: 'js-date-slider__scrollbar-drag',
                dragSize: 100
            },
            breakpoints: {
                [window.breakpoints['sm']]: {
                    scrollbar: {
                        dragSize: 200
                    }
                },
                [window.breakpoints['md']]: {
                    scrollbar: {
                        dragSize: 400
                    }
                }
            }
        });
    }

    return swiperEl;

};

(function () {

    var dateSliderEls = document.querySelectorAll('.js-date-slider');
    dateSliderEls.forEach(function (dateSliderEl) {
        window.setUpDateSlider(dateSliderEl);
    });

})();